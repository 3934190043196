<template>
  <v-snackbar
    :color="notification.color"
    :timeout="notification.timeout"
    top
    centered
    v-model="notification.showing"
  >
    {{ notification.text }}

    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" icon color="transparent" @click="notification.showing = false">
        <v-icon color="white"> mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'NotificationSnack',
  data: () => ({}),
  computed: {
    ...mapState(['notification']),
  },
};
</script>
